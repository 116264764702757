var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Usuários")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v("Criar usuário")])],1),_c('v-card-text',[[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.users,"search":_vm.search,"loading":_vm.loadingDataTable,"loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.is_superuser",fn:function(ref){
var item = ref.item;
return [(item.is_superuser)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(item.cnpj))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.routeEditUser(item.id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }