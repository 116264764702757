import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userStore } from '@/store';
let AdminUsers = class AdminUsers extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Email',
                sortable: true,
                value: 'email',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Nome Completo',
                sortable: true,
                value: 'full_name',
                align: 'left',
                filterable: true,
            },
            {
                text: 'Ativo',
                sortable: true,
                value: 'is_active',
                align: 'left',
            },
            {
                text: 'Administrador',
                sortable: true,
                value: 'is_superuser',
                align: 'left',
            },
            {
                text: 'Unidade',
                sortable: true,
                value: 'company.nome_fantasia',
                align: 'left',
            },
            {
                text: 'CNPJ',
                sortable: true,
                value: 'company.cnpj',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loadingDataTable = true;
        this.search = '';
    }
    get users() {
        return userStore.users;
    }
    async mounted() {
        await userStore.getUsers(false);
        this.loadingDataTable = false;
    }
    routeEditUser(id) {
        this.$router.push({ name: 'main-admin-users-edit', params: { id } });
    }
};
AdminUsers = __decorate([
    Component
], AdminUsers);
export default AdminUsers;
